<template>
    <div class="d6-switch">
         <div class="d6-switch-tit">
        {{projectData.project}}-切换画面
      </div>
      <div class="d6-switch-container flex">
        <div class="d6-con-l">
                <div class="l-t-th l-t-tr flex">
                    <div class="cell cell1"></div>
                    <div class="cell cell1">回路名称</div>
                    <div class="cell">选择开关</div>
                    <div class="cell">下限</div>
                    <div class="cell">上限</div>
                    <div class="cell">RASO</div>
                    <div class="cell">DCS</div>
                    <div class="cell cell2">设定点</div>
                </div>
                <div class="l-t-t flex" >
                    <div class="cell-left">
                        精馏
                    </div>
                    <div class="cell-right">
                        <div class="cell-r-tr flex"
                         v-for="(item,index) in itemList"
                        >
                            <div class="cell cell1">
                                {{item.name}}
                            </div>
                            <div class="cell cell2"
                            >
                                <span :style="infoList.DB1S[`${item.node}_SW`] | bgFillter"></span>
                            </div>
                            <div class="cell"
                             @click="toCompon(2, `${item.node}_L`, 'DB1S', `${item.node}_L`,`${item.name}DCS优化输出下限_RASO`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_L`],`${item.node}_L`, 'DB1S', `${item.node}_L`)"
                            >
                                 {{infoList.DB1S[`${item.node}_L`]}}
                            </div>
                            <div class="cell"
                             @click="toCompon(2, `${item.node}_H`, 'DB1S', `${item.node}_H`,`${item.name}DCS优化输出上限_RASO`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_H`],`${item.node}_H`, 'DB1S', `${item.node}_H`)"
                            >
                                  {{infoList.DB1S[`${item.node}_H`]}}
                            </div>
                            <div class="cell"
                             @click="toCompon(2, `${item.node}_MV`, 'DB1S', `${item.node}_MV`,`${item.name}DCS优化输出`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_MV`],`${item.node}_MV`, 'DB1S', `${item.node}_MV`)"
                            >
                                 {{infoList.DB1S[`${item.node}_MV`]}}
                            </div>
                            <div class="cell"
                              @click="toCompon(2, `${item.node}_AO`, 'DB1S', `${item.node}_AO`,`${item.name}DCS优化输出`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_AO`],`${item.node}_AO`, 'DB1S', `${item.node}_AO`)"
                            >
                                  {{infoList.DB1S[`${item.node}_AO`]}}
                            </div>
                            <div class="cell cell3">
                               {{item.name1}}
                                <span v-if='item.name1'
                                @click="toCompon(2, `${item.node}_SP`, 'DB1S', `${item.node}_SP`,`${item.name}${item.name1}`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_SP`],`${item.node}_SP`, 'DB1S', `${item.node}_SP`)"
                                >
                                     {{infoList.DB1S[`${item.node}_SP`]}}
                               </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-t-t  l-t-c flex" >
                    <div class="cell-left">
                        反应釜
                    </div>
                    <div class="cell-right">
                        <div class="cell-r-tr flex"
                         v-for="(item,index) in itemList1"
                        >
                            <div class="cell cell1">
                                {{item.name}}
                            </div>
                            <div class="cell cell2">
                                <span :style="infoList.DB1S[`${item.node}_SW`] | bgFillter"></span>
                            </div>
                                <div class="cell"
                             @click="toCompon(2, `${item.node}_L`, 'DB1S', `${item.node}_L`,`${item.name}DCS优化输出下限_RASO`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_L`],`${item.node}_L`, 'DB1S', `${item.node}_L`)"
                            >
                                 {{infoList.DB1S[`${item.node}_L`]}}
                            </div>
                            <div class="cell"
                             @click="toCompon(2, `${item.node}_H`, 'DB1S', `${item.node}_H`,`${item.name}DCS优化输出上限_RASO`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_H`],`${item.node}_H`, 'DB1S', `${item.node}_H`)"
                            >
                                  {{infoList.DB1S[`${item.node}_H`]}}
                            </div>
                            <div class="cell"
                             @click="toCompon(2, `${item.node}_MV`, 'DB1S', `${item.node}_MV`,`${item.name}DCS优化输出`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_MV`],`${item.node}_MV`, 'DB1S', `${item.node}_MV`)"
                            >
                                 {{infoList.DB1S[`${item.node}_MV`]}}
                            </div>
                            <div class="cell"
                              @click="toCompon(2, `${item.node}_AO`, 'DB1S', `${item.node}_AO`,`${item.name}DCS优化输出`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_AO`],`${item.node}_AO`, 'DB1S', `${item.node}_AO`)"
                            >
                                  {{infoList.DB1S[`${item.node}_AO`]}}
                            </div>
                            <div class="cell cell3">
                               {{item.name1}}
                                <span v-if='item.name1'
                                @click="toCompon(2, `${item.node}_SP`, 'DB1S', `${item.node}_SP`,`${item.name}${item.name1}`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_SP`],`${item.node}_SP`, 'DB1S', `${item.node}_SP`)"
                                >
                                     {{infoList.DB1S[`${item.node}_SP`]}}
                               </span>
                            </div>
                        </div>
                    </div>
                </div>
                   <div class="l-t-t  l-t-b flex" >
                    <div class="cell-left">
                        公用
                    </div>
                    <div class="cell-right">
                        <div class="cell-r-tr flex"
                         v-for="(item,index) in itemList2"
                        >
                            <div class="cell cell1">
                                {{item.name}}
                            </div>
                                 <div class="cell cell2">
                                <span :style="infoList.DB1S[`${item.node}_SW`] | bgFillter"></span>
                            </div>
                                <div class="cell"
                             @click="toCompon(2, `${item.node}_L`, 'DB1S', `${item.node}_L`,`${item.name}DCS优化输出下限_RASO`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_L`],`${item.node}_L`, 'DB1S', `${item.node}_L`)"
                            >
                                 {{infoList.DB1S[`${item.node}_L`]}}
                            </div>
                            <div class="cell"
                             @click="toCompon(2, `${item.node}_H`, 'DB1S', `${item.node}_H`,`${item.name}DCS优化输出上限_RASO`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_H`],`${item.node}_H`, 'DB1S', `${item.node}_H`)"
                            >
                                  {{infoList.DB1S[`${item.node}_H`]}}
                            </div>
                            <div class="cell"
                             @click="toCompon(2, `${item.node}_MV`, 'DB1S', `${item.node}_MV`,`${item.name}DCS优化输出`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_MV`],`${item.node}_MV`, 'DB1S', `${item.node}_MV`)"
                            >
                                 {{infoList.DB1S[`${item.node}_MV`]}}
                            </div>
                            <div class="cell"
                              @click="toCompon(2, `${item.node}_AO`, 'DB1S', `${item.node}_AO`,`${item.name}DCS优化输出`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_AO`],`${item.node}_AO`, 'DB1S', `${item.node}_AO`)"
                            >
                                  {{infoList.DB1S[`${item.node}_AO`]}}
                            </div>
                            <div class="cell cell3">
                               {{item.name1}}
                                <span v-if='item.name1'
                                @click="toCompon(2, `${item.node}_SP`, 'DB1S', `${item.node}_SP`,`${item.name}${item.name1}`)"
                            @dblclick="Cclick(infoList.DB1S[`${item.node}_SP`],`${item.node}_SP`, 'DB1S', `${item.node}_SP`)"
                                >
                                     {{infoList.DB1S[`${item.node}_SP`]}}
                               </span>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div class="d6-con-r">
            <div class="r-t">
                <div class="r-t-t flex">
                    <div class="cell">控制方式切换</div>
                    <div class="cell flex align-cen">
                        <span></span> DCS切换
                    </div>
                    <div class="cell flex align-cen">
                        <span style="background:#0de0ac"></span> RASO切换
                    </div>
                </div>
                <div class="r-t-b flex align-cen">
                    <div class="cell">
                        <p class="p1">公用区域</p>
                        <p class="p2">（注：谨慎操作）</p>
                    </div>
                    <div class="cell">
                        <div class="cell-1 flex align-cen">
                            <p class="p3">一键全切</p>
                            <span :style="infoList.DB1S.APC_FW02 | bgFillter">  </span>
                        </div>
                        <div class="cell-1 flex align-cen">
                            <p class="p3">公用切</p>
                             <span :style="infoList.DB1S.APC_FW06 | bgFillter">  </span>
                        </div>
                    </div>
                    <div class="cell">
                            <div class="cell-1 flex align-cen">
                            <p class="p3">精馏全切</p>
                             <span :style="infoList.DB1S.APC_FW07 | bgFillter">  </span>
                        </div>
                        <div class="cell-1 flex align-cen">
                            <p class="p3">反应釜全切</p>
                            <span :style="infoList.DB1S.APC_FW04 | bgFillter">  </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="r-zw flex align-cen">
                <div class="cell-btn" >启动远程协助系统</div>
                 <div class="cell-btn cell-btn1 " >停止远程协助系统</div>
            </div>
            <div class="r-c flex align-cen">
                <div class="cell">系统状态</div>
                 <div class="cell">
                    <div class="cell-btn" @click="openTip">
                        启动远程协助系统
                    </div>
                 </div>
                  <div class="cell " @click="openTip">
                    <div class="cell-btn cell-btn1">
                        停止远程协助系统
                    </div>
                    </div>
            </div>
            <div class="r-zw"></div>
            <div class="r-b flex align-cen">
                <div class="r-b-c">
                    <div class="r-b-c-item flex align-cen">
                     <div class="cell">
                        DCS 心跳 
                        <span
                          @click="toCompon(2, 'HEART', 'DB1S', 'HEART','DCS 心跳 ')"
                            @dblclick="Cclick(infoList.DB1S.HEART,'HEART', 'DB1S', 'HEART')"
                        >
                            {{infoList.DB1S.HEART}}
                        </span>
                    </div>
                    <div class="cell">
                       RASO 通讯{{infoList.PUB.HTERR ? '正常' : '故障'}}
                    </div>
                    <div class="cell">
                        SIS 心跳 
                        <span
                             @click="toCompon(2, 'HEARTS', 'DB1S', 'SISHEART',' SIS 心跳 ')"
                            @dblclick="Cclick(infoList.DB1S.HEARTS,'HEARTS', 'DB1S', 'SISHEART')"
                        >
                        {{infoList.DB1S.HEARTS}}
                        </span>
                    </div>
                    </div>
                        <div class="r-b-c-item flex align-cen">
                     <div class="cell">
                        RASO 心跳
                         <span
                          @click="toCompon(2, 'HEART1', 'DB1S', 'HEART1','RASO 心跳 ')"
                            @dblclick="Cclick(infoList.DB1S.HEART1,'HEART1', 'DB1S', 'HEART1')"
                         >
                            {{infoList.DB1S.HEART1}}
                        </span>
                    </div>
                    <div class="cell">
                      DCS 通讯{{infoList.PUB.HTERR ? '正常' : '故障'}}
                    </div>
                    <div class="cell">
                        SIS 系统通讯
                    </div>
                    </div>
                        <div class="r-b-c-item flex align-cen">
                     <div class="cell">
                       通讯时间设定
                       <span
                        @click="toCompon(2, 'TXSJ', 'DB1S', 'TXSJ_JLT','通讯时间设定')"
                        @dblclick="Cclick(infoList.DB1S.TXSJ,'TXSJ', 'DB1S', 'TXSJ_JLT')"
                       >
                         {{infoList.DB1S.TXSJ}}S</span>
                    </div>
                    <div class="cell cell1" :style="infoList.PUB.HTERR | bgFillter">
                       
                    </div>
                    <div class="cell">
                       SIS 通讯{{infoList.PUB.SISHEARTERR ? '正常' : '故障'}}
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
    </div>
</template>
<script>
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name:'Jlyxoj',
  	components: {
			Historical
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {}; // 默认值
				},
			},
		},
  data () {
    return {
      chName: '',
				isComShow: false,
				isHshow: false,
				isClose: false,
				historyname: "",
				node: "",
				Lkname: "",
				spotArr: [],
				grouptime: null,
                itemList:[
                    {
                        id:1,
                        name:'进料泵',
                        name1:'进料流量设定：',
                        node:'BP101',

                    },{
                        id:2,
                        name:'冷凝阀',
                        name1:'再沸器温度设定：',
                        node:'FV105',
                    },{
                        id:3,
                        name:'回流泵',
                        name1:'塔釜液位设定：',
                        node:'BP102',
                    },{
                        id:4,
                        name:'采出泵',
                        name1:'回流罐液位设定：',
                        node:'BP104',
                    },{
                        id:5,
                        name:'残液泵',
                        name1:'蒸汽调节塔釜温度设定：',
                        node:'BP103',
                    },{
                        id:6,
                        name:'再沸器电加热',
                        name1:'塔顶回流器温度设定：',
                        node:'E101A',
                    },{
                        id:7,
                        name:'再沸器蒸汽加热',
                        name1:'',
                        node:'FV302',
                    }
                    ,{
                        id:8,
                        name:'塔顶压力调节',
                        name1:'塔顶压力设定：',
                        node:'PV106',
                    }
                ],
                itemList1:[
                    {
                        id:9,
                        name:'2#原料罐电加热',
                        name1:'原料罐温度设定：',
                        node:'TZ401'
                    },{
                        id:10,
                        name:'反应釜电加热',
                        name1:'釜内温度设定：',
                        node:'TZ402'
                    },{
                        id:11,
                        name:'回流泵',
                        name1:'釜内温度设定：',
                        node:'FV406',
                    },{
                        id:12,
                        name:'冷凝器冷却水',
                        name1:'冷凝器出口温度设定：',
                        node:'FV405',
                    },{
                        id:13,
                        name:'回流泵调节',
                        name1:'回流流量设定：',
                        node:'BP401',
                    },{
                        id:14,
                        name:'搅拌电机调节',
                        name1:'搅拌转速设定：',
                        node:'BP402',
                    },{
                        id:15,
                        name:'1#原料罐出口阀',
                        name1:'1#原料罐液位设定：',
                        node:'FV401',
                    },{
                        id:16,
                        name:'回流阀',
                        name1:'中间罐液位设定：',
                        node:'FV403',
                    },{
                        id:17,
                        name:'采出阀',
                        name1:'中间罐液位设定：',
                        node:'FV404',
                    },{
                        id:18,
                        name:'2#原料罐出口阀',
                        name1:'2#原料罐液位设定：',
                        node:'FV402',
                    }
                ],
                  itemList2:[
                    {
                        id:19,
                        name:'公用水泵',
                        name1:'泵出口压力设定：',
                        node:'BP001'
                    },
                    {
                        id:20,
                        name:'公用废水泵',
                        name1:'',
                        node:'BP002'
                    }
                  ]
    }
  },
  watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
    created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) : [];
		},
    methods: {
        // 
        openTip(){
            this.$message.error("请返回通讯总览页面执行操作");
        },
      	Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
      isClose1() {
				this.isHshow = false;
			},
      	toCompon(key, name, name2, name3, name4) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (this.isMshow = true);
						case 1:
							return (this.isFshow = true);
						case 2:
							return (this.isHshow = true, this.chName = name4);
					}
				}, 300);
			},
    },
    filters: {
      //        颜色过滤器
      bgFillter(val){
        if (val ) {
		return 'background:#0de0ac';
				} else{
         return 'background:red';
        }
      },
      // 文字过滤器
      textFillter: (val) => {
				if (val) {
					return '参与';
				} else{
        return '不参与';
       }
			},
      text1Fillter: (val) => {
				if (val) {
					return '自动';
				} else{
        return '手动';
       }
			},
    }
}
</script>
<style lang="scss" scoped>
.d6-switch{
.d6-switch-tit{
    width: 90vw;
    height: 8vh;
    background: url("~@/assets/images/ding1.png") no-repeat center center;
    background-size: 100% 100%;
    margin:0vh 2vw;
    text-align: center;
    line-height: 7vh;
    color: #0ef7ff;
    font-size: 1.3vw;
}
.d6-switch-container{
    width: 96vw;
    margin: 0 auto;
    height: 72vh;
    margin-top: 1vh;
    .d6-con-l{
        width: 61vw;
        height: 100%;
        border: 1px solid rgba(0,228,255,.2);
      .l-t-tr{
        width: 100%;
        height: 3.4vh;
        line-height: 3.4vh;
        font-size: .9vw;
        text-align: center;
        .cell{
            width: 5vw;
            border-right: 1px solid rgba(0,228,255,.2);
            box-sizing: border-box;
            border-bottom: 1px solid rgba(0,228,255,.2);
        }
        .cell:last-child{border-right: none;}
        .cell1{
            width: 10vw;
         box-sizing: border-box;
        }
        .cell2{
            width: 16vw;
        }
      }

      .l-t-th{color: #458ca4;}
      
      .l-t-t{
        width: 100%;
        height: 27.2vh;
        .cell-left{
            width: 10vw;
            height: 27.2vh;
            text-align: center;
            font-size: 1vw;
            color: #fff;
            line-height: 27.2vh;
            border-right: 1px solid rgba(0,228,255,.2);
            box-sizing: border-box;
            border-bottom: 1px solid rgba(0,228,255,.2);
        }
        .cell-right{
            width: 51vw;
            height: 27.2vh;
            .cell-r-tr{
                height: 3.4vh;
                width: 100%;
                line-height: 3.4vh;
                .cell{
                    color: #fff;
                    width: 5vw;
                    border-right: 1px solid rgba(0,228,255,.2);
                    box-sizing: border-box;
                    border-bottom: 1px solid rgba(0,228,255,.2);
                    text-align: center;
                }
                 .cell:last-child{border-right: none;}
                 .cell1{
                    width: 10vw;
                    color: #8aeaff;
                 }
                 .cell2{
                    span{
                        width: 3vw;
                        height: 2vh;
                        background-color: #cf284b;
                        display: block;
                        margin: 0 auto;
                        margin-top: .7vh;
                        border-radius: 2px;
                    }
                 }
                 .cell3{
                     width: 16vw;
                      color: #8aeaff;
                      span{
                        color: #01ffba;
                      }
                 }
            }
        }
      }
       .l-t-c{
           height: 34vh !important;
           .cell-left,.cell-right{
             height: 34vh !important;
           }
      .cell-left{
        line-height: 34vh !important;
       }
       }
      .l-t-b{
           height: 7.4vh !important;
           .cell-left,.cell-right{
             height: 7.4vh !important;
           }
      .cell-left{
        line-height: 7.4vh !important;
        border-bottom: none;
       }
        .cell-right{
              .cell-r-tr{
                height: 3.7vh;
                width: 100%;
                line-height: 3.7vh;
              }
              .cell-r-tr:last-child{
                .cell{
                    border-bottom: none;
                }
              }
        }
       }
    }
    .d6-con-r{
        width: 33vw;
        margin-left: 2vw;
        height: 100%;
        .r-t{
            width: 100%;
            height: 23.8vh;
             border: 1px solid rgba(0,228,255,.2);
             .r-t-t{
                width: 100%;
                height: 3.4vh;
                line-height: 3.4vh;
                color: #458ca4;
                border-bottom:1px solid rgba(0,228,255,.2);;
                .cell{
                    width: 33%;
                    text-align: center;
                    span{
                        width: 3vw;
                        height: 2vh;
                        display: block;
                        background: red;
                        margin-right: .5vw;
                        border-radius: 2px;
                    }
                }
             }
             .r-t-b{
                width: 100%;
                height: calc(100% - 3.4vh);
                .cell{
                    width: 33%;
                    text-align: center;
                    .p1{
                        font-size: 1.5vw;
                        color: #fff;
                    }
                    .p2{
                        color: #ff0f37;
                    }
                    .p3{
                        width: 5vw;
                        text-align: right;
                    }
                    .cell-1{
                        color: #8aeaff;
                        font-size: .9vw;
                        span{
                            width: 3vw;
                            height: 2vh;
                            border-radius: 2px;
                            display: block;
                            margin-left: .5vw;
                        }
                    }
                }
             }
        }
        .r-zw{
            width: 100%;
            height: 3.4vh;
            justify-content: end;
            .cell-btn{
                width: 9vw;
                height: 3vh;
                line-height: 3vh;
                text-align: center;
                border-radius: 2px;
                background: #091e3b;
                color: #2179f1;
                border: 1px solid #2179f1;
                box-sizing: border-box;
            }
            .cell-btn1{
                border: 1px solid #cf284b;
                background: #180408;
                margin-left: 2vw;
                color: #cf284b;
            }
        }
        .r-c{
             width: 100%;
            height: 17vh;
             border: 1px solid rgba(0,228,255,.2);
             box-sizing: border-box;
             .cell{
                width: 33.3%;
                text-align: center;
                color: #fff;
                .cell-btn{
                width: 9vw;
                height: 3vh;
                line-height: 3vh;
                text-align: center;
                border-radius: 2px;
                background: #2179f1;
                box-sizing: border-box;
                cursor: pointer;
                }
                 .cell-btn1{
                background: #cf284b;
            }
             }
        }
        .r-b{
            width: 100%;
            height: 24.3vh;
             border: 1px solid rgba(0,228,255,.2);
             .r-b-c{
                width: 100%;
             }
             .r-b-c-item{
                margin-bottom: 1vh;
                .cell{
                    width: 33.3%;
                    text-align: center;
                    color: #8aeaff;
                    span{
                        color: #fff;
                        padding-left: 1vw;
                    }
                }
                .cell1{
                    width: 6vw;
                    height: 2vh;
                    border-radius: 2px;
                    margin: 0 auto;
                }
             }
        }
    }
}
}
</style>